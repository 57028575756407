/* Here you can add other styles */

.card-rounded {
  border-radius: 20px !important;
}

a.c-sidebar-nav-link.c-active {
  background-color: #321fdb !important;
}

a.c-sidebar-nav-link.c-active:hover {
  background-color: #321fdb !important;
}

a.c-sidebar-nav-link:hover {
  background-color: grey !important;
}

div.fr-wrapper > div > a {
  font-size: 0px !important;
  padding: 0px !important;
  height: 0px !important;
}

.CalendarDay__selected_span {
  background: #321fdb;
  color: white;
  border: 1px solid silver;
}
.CalendarDay__selected {
  background: #321fdb;
  color: white;
}

@media print {
  body {
    margin-top: 40px;
    margin-bottom: 20px;

  }
  .pagebreak {
    page-break-before: always;
  }

  .pagebreak-after {
    page-break-after: always;
  }

  .page-break-void {
    page-break-inside: avoid;
  }

  .print-survey-lingkungan-margin {
    margin: 25px 15px;
  }

  .hide-on-print {
    display: none;
  }

  .margin-print-pembahasan {
    margin-top: 25px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen {
  .show-on-print {
    display: none;
  }
}

/* ------------ soal navigation ------------ */
.soal-item {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-weight: bold; */
}

.soal-item-container > div {
  margin: 4px;
}

.zero-progress::after{
  content: '0%';
}
.zero-progress {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

/* ------------ box soal ------------ */
.box-soal {
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  border-top: 4px solid #ddd;
  border-bottom: 4px solid #ddd;
}

.table-report-pencapaian {
  /* background-color: red; */
}

.table-report-pencapaian tbody > tr > td {
  padding: 5px;
  border: 1px solid gray;
}

.text-bold {
  font-weight: 700;
}

.subtitle-akm {
  margin-top: 20px;
  padding: 0;
  margin-bottom: 5px !important;
}

.bg-orange{
  background-color: #c24e00;
}
